/* PriceCard.css */

.price-card {
    background-color: #1e1e1e; /* Dark gray card background for contrast */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    max-width: 300px;
    margin: 20px auto;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff; /* White title text */
    margin: 0;
}

.price {
    font-size: 36px;
    color: #ff4444; /* Light red for price text */
    margin-top: 10px;
}