.container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #000000;
}

.price-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.price-box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  max-width: 300px;
}

@media (min-width: 600px) {
  .price-container {
    flex-direction: row;
  }
}

body {
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text color for readability */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Center content vertically */
}